import { pxToRem } from "@input-output-hk/px-to-rem";
import Link from "next/link";
import { NextRouter, useRouter } from "next/router";
import React, { useMemo } from "react";
import styled from "styled-components";
import { BreadcrumbArgument } from "./BreadcrumbArgument";
import { forTabletPortraitDown } from "../../tokens/media-queries";
import CaretIcon from "../icons/Caret";
import { CATEGORY_LABELS, isKnownCategory } from "../navigation/shared";

const BreadcrumbElement = styled.nav`
  padding: var(--spacing-large);
  margin: auto;
  max-width: var(--page-max-width);
  @media ${forTabletPortraitDown} {
    display: none;
  }
`;

const BreadcrumbList = styled.ol`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const BreadcrumbItem = styled.li`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
`;

const BreadcrumbText = styled.a`
  color: var(--link-color);
  display: block;

  &[aria-current="page"] {
    text-decoration: none;
    color: var(--default-text-color);
    pointer-events: none;
    cursor: default;
  }
`;

const StyledPrimaryArrow = styled(CaretIcon)`
  margin: 0 ${pxToRem(8)};
  color: var(--link-color);
  transform: rotate(-90deg);
`;

const convertBreadcrumb = (url?: string): React.ReactNode => {
  if (url === undefined) return;

  const cleanUrl = url?.split(/[?#]/)[0];

  if (
    Object.keys(CATEGORY_LABELS).some((allowedUrls) => allowedUrls === cleanUrl)
  ) {
    switch (cleanUrl) {
      case "content": {
        return "All Content";
      }

      case "article": {
        return "Articles";
      }

      case "video": {
        return "Videos";
      }

      case "faq": {
        return "FAQs";
      }

      case "developer": {
        return "Developer Resources";
      }

      case "glossary": {
        return "Glossary";
      }

      case "other": {
        return "Other";
      }

      case "development-update": {
        return "Development Updates";
      }

      case "infographic": {
        return "Infographics";
      }

      default: {
        return "Content";
      }
    }
  } else {
    return cleanUrl
      ?.replace(/-/gi, " ")
      .replace(/\b(\w)/g, (firstLetter) => firstLetter.toUpperCase());
  }
};

const createPathArray = (
  router: NextRouter,
  breadcrumbArgument: BreadcrumbArgument
): Array<Breadcrumb> => {
  let linkPath = router.asPath.split("/");

  // Remove empty string element from root slash
  linkPath.shift();

  return linkPath.flatMap((path, index) => {
    let prevHref = linkPath.slice(0, index).join("/");
    if (prevHref) {
      prevHref = "/" + prevHref;
    }
    const isLast = index === linkPath.length - 1;

    // e.g. /contribute?category=developer
    if (breadcrumbArgument.argumentType === "contributionType") {
      if (isLast) {
        return [
          {
            breadcrumb: "contribute",
            href: prevHref + "/contribute-help"
          },
          {
            breadcrumb: breadcrumbArgument.argumentContents ?? path,
            href: prevHref + "/" + path
          }
        ];
      }
    }
    // e.g. /article/lorem-ipsum
    if (breadcrumbArgument.argumentType === "articleName") {
      if (isLast) {
        return {
          breadcrumb: breadcrumbArgument.argumentContents ?? path,
          href: prevHref + "/" + path
        };
      }
      if (isKnownCategory(path)) {
        return [
          {
            breadcrumb: "content",
            href: "/content"
          },
          {
            breadcrumb: path,
            href: prevHref + "/" + path
          }
        ];
      }
    }
    // e.g. /search?q=test
    if (breadcrumbArgument.argumentType === "queryUrl") {
      if (isLast) {
        const { q } = router.query;
        return [
          {
            breadcrumb: "search?q=",
            href: prevHref + "/search?q="
          },
          {
            breadcrumb: breadcrumbArgument.argumentContents ?? path,
            href: prevHref + `/search?q=${encodeURIComponent(String(q))}`
          }
        ];
      }
    }

    return {
      breadcrumb: path,
      href: prevHref + "/" + path
    };
  });
};

const trimBreadcrumbIfNecessary = (str: string) => {
  if (str.length > 36) {
    return str.slice(0, 36) + "…";
  }
  return str;
};

export interface Breadcrumb {
  breadcrumb: string;
  href: string;
}
export interface BreadcrumbsProps {
  rootLabel?: string;
  breadcrumbArgument?: BreadcrumbArgument;
}

const defaultProps: BreadcrumbsProps = {
  rootLabel: "Home",
  breadcrumbArgument: { argumentType: undefined, argumentContents: undefined }
};

const Breadcrumbs = ({ rootLabel, breadcrumbArgument }: BreadcrumbsProps) => {
  const router = useRouter();

  const breadcrumbs: Breadcrumb[] = useMemo(() => {
    return breadcrumbArgument
      ? createPathArray(router, breadcrumbArgument)
      : [];
  }, [router, breadcrumbArgument]);

  return (
    <BreadcrumbElement aria-label="Breadcrumb">
      <BreadcrumbList>
        {
          <BreadcrumbItem>
            <Link href="/" passHref>
              <BreadcrumbText>{convertBreadcrumb(rootLabel)}</BreadcrumbText>
            </Link>
          </BreadcrumbItem>
        }
        {breadcrumbs.length > 0 &&
          breadcrumbs.map((breadcrumb, index) => {
            const isLast = index === breadcrumbs.length - 1;
            const content = trimBreadcrumbIfNecessary(breadcrumb.breadcrumb);
            return (
              <BreadcrumbItem key={breadcrumb.href}>
                <StyledPrimaryArrow aria-hidden />
                <Link href={breadcrumb.href} passHref>
                  <BreadcrumbText aria-current={isLast ? "page" : undefined}>
                    {isLast &&
                    breadcrumbArgument?.argumentType === "articleName"
                      ? content
                      : convertBreadcrumb(content)}
                  </BreadcrumbText>
                </Link>
              </BreadcrumbItem>
            );
          })}
      </BreadcrumbList>
    </BreadcrumbElement>
  );
};

Breadcrumbs.defaultProps = defaultProps;

export default Breadcrumbs;
