import { StructuredCommentThread } from "../../data/github/types/comment";
import { Nullable } from "../../utils/types";

export function selectedCommentsMatch(
  comments: StructuredCommentThread[],
  selected?: Nullable<StructuredCommentThread[]>
) {
  return (
    selected &&
    selected.some((c) => {
      return comments.some(
        (rc) => rc.about === c.about && rc.bodyPosition === c.bodyPosition
      );
    })
  );
}
