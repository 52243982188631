import { pxToRem } from "@input-output-hk/px-to-rem";
import styled from "styled-components";

export const FormFieldLabel = styled.label`
  display: block;
  margin-bottom: ${pxToRem(4)};
  color: var(--text-color-muted);
  font-size: var(--text-level3-size);
  line-height: var(--text-level3-lineHeight);
`;
