import React, { createContext, useContext } from "react";
import styled, { css } from "styled-components";
import { StructuredCommentThread } from "../../data/github/types/comment";
import colors from "../../tokens/colors";
import { trueOrUndef } from "../../utils/dom-attribute-helpers";
import { Maybe } from "../../utils/types";
import { selectedCommentsMatch } from "./selectors";

export const RelatedContentContext = createContext<{
  selected?: Maybe<StructuredCommentThread[]>;
  comments: Maybe<StructuredCommentThread[]>;
  onClick?: (
    ev: React.MouseEvent<any>,
    comments: StructuredCommentThread[]
  ) => void;
  renderRelated?: (comments: StructuredCommentThread[]) => React.ReactNode;
}>({ comments: [] });

export type RelatedContentSelector = NonNullable<
  StructuredCommentThread["about"]
>;

export const useRelatedContentContext = (
  selector: RelatedContentSelector,
  position?: number
) => {
  const ctx = useContext(RelatedContentContext);
  let relatedComments = ctx.comments?.filter((c) => c.about === selector);
  if (position) {
    relatedComments = relatedComments?.filter(
      (c) => c.bodyPosition === position
    );
  }
  const hasRelated = !!relatedComments?.length;
  const isSelected =
    hasRelated && selectedCommentsMatch(relatedComments!, ctx.selected);
  const related = hasRelated ? ctx.renderRelated?.(relatedComments!) : null;
  return {
    onClick: (e: React.MouseEvent) =>
      hasRelated && ctx.onClick?.(e, relatedComments!),
    isSelected,
    hasRelated,
    related
  };
};

type Point = {
  line: number;
  column: number;
  offset?: number;
};

type Position = {
  start: Point;
  end: Point;
  indent: number;
};

export const indicatorParentCss = css`
  position: relative;
  &[data-is-selected] {
    /* background-color: ${colors.action.warn}; */
    background-color: rgb(247, 204, 98, 0.2);
  }
`;

const CustomMarkdownElement = styled.span`
  ${indicatorParentCss}
`;

type CustomProps = React.ComponentPropsWithoutRef<typeof CustomMarkdownElement>;

const EMPTY_ELEMENTS = new Set([
  "area",
  "base",
  "br",
  "col",
  "embed",
  "hr",
  "img",
  "input",
  "keygen",
  "link",
  "meta",
  "param",
  "source",
  "track",
  "wbr"
]);

function createComponent(comp: string) {
  /**
   * Some elements dont allow children, so we might have to wrap
   * them to get the indicator...
   */
  const isEmpty = EMPTY_ELEMENTS.has(comp);
  /**
   * Our super cool "styled-component"
   */
  const CustomComponent: React.FC<
    CustomProps & {
      sourcePosition: Position;
    }
  > = ({ sourcePosition, children, ...props }) => {
    const line = sourcePosition.start.line;
    const {
      hasRelated,
      isSelected,
      related: Related,
      onClick
    } = useRelatedContentContext("body", line);
    const sharedProps = {
      ...(props as any),
      as: comp,
      onClick,
      "data-is-selected": trueOrUndef(isSelected),
      "data-has-related": trueOrUndef(hasRelated)
    } as CustomProps;

    const Content = isEmpty ? (
      <CustomMarkdownElement {...sharedProps} />
    ) : (
      <CustomMarkdownElement {...sharedProps}>
        {children}
        {Related}
      </CustomMarkdownElement>
    );
    const shouldWrap = isEmpty && hasRelated;
    return shouldWrap ? (
      <CustomMarkdownElement>
        {Content}
        {Related}
      </CustomMarkdownElement>
    ) : (
      Content
    );
  };
  CustomComponent.displayName = `CustomComponent(${comp})`;
  return CustomComponent;
}

const components = new Map<string, ReturnType<typeof createComponent>>();
export const customComponents = new Proxy(
  {},
  {
    // Makes `Object.prototype.hasOwnProperty.call(customComponents, 'thing')` work!
    getOwnPropertyDescriptor() {
      return { configurable: true, enumerable: true };
    },
    get: (_, key: string) => {
      !components.has(key) && components.set(key, createComponent(key));
      return components.get(key);
    }
  }
);
