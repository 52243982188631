import {
  Combobox as BaseCombobox,
  ComboboxInput as BaseComboboxInput,
  ComboboxInputProps,
  ComboboxList as BaseComboboxList,
  ComboboxOption as BaseComboboxOption,
  ComboboxOptionText as BaseComboboxOptionText,
  ComboboxPopover as BaseComboboxPopover,
  ComboboxProps
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import React, { useRef } from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { trueOrUndef } from "../../utils/dom-attribute-helpers";
import { dropDownArrowCss } from "./dropdown-arrow-css";

type Props = ComboboxProps & {
  hasError?: boolean;
  disabled?: boolean;
  name?: string;
  value?: ComboboxInputProps["value"];
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
};

const ComboboxElement = styled(BaseCombobox)``;
const ComboboxInput = styled(BaseComboboxInput)`
  border-radius: 0.5rem;
  border: ${pxToRem(1)} solid var(--input-border-color);
  color: var(--select-text-color);
  font-family: var(--select-text-font-family);
  padding: var(--select-box-primary-padding);
  font-size: var(--select-primary-text-size);
  width: 100%;

  ${dropDownArrowCss}

  &:hover:not([disabled]) {
    border-color: var(--input-border-color-hover);
  }
  &::placeholder {
    color: var(--input-placeholder-color);
  }
  &[aria-invalid] {
    border-color: var(--input-error-border-color);
  }
  &[disabled] {
    background-color: var(--input-disabled-background-color);
  }
`;
const ComboboxPopover = styled(BaseComboboxPopover)`
  border-radius: 0.5rem;
  border: var(--card-stroke);
  max-height: ${pxToRem(42 * 6)};
  overflow: auto;
`;
const ComboboxList = styled(BaseComboboxList)`
  & > * + * {
    border-top: var(--card-stroke);
  }
`;
export const ComboboxOption = styled(BaseComboboxOption)`
  background: #fff;
  color: var(--select-list-color-hover);
  font-weight: normal;
  padding: var(--spacing-default) ${pxToRem(15)};
  [data-suggested-value] {
    font-weight: normal;
  }

  &:hover,
  &[data-highlighted] {
    background: var(--select-list-background-color);
    color: var(--select-list-color-hover);
  }

  &:first-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
  }
`;
export const ComboboxOptionText = styled(BaseComboboxOptionText)``;

const Combobox = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      children,
      value,
      onChange,
      placeholder,
      hasError,
      onKeyDown: _onKeyDown,
      disabled,
      ...props
    },
    ref
  ) => {
    const popoverRef = useRef<HTMLDivElement>(null);
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      _onKeyDown?.(event);
      if (!event.isDefaultPrevented()) {
        const container = popoverRef.current;
        if (!container) {
          return;
        }
        window.requestAnimationFrame(() => {
          const element = container.querySelector(
            "[aria-selected=true]"
          ) as HTMLElement;
          if (element) {
            const top = element.offsetTop - container.scrollTop;
            const bottom =
              container.scrollTop +
              container.clientHeight -
              (element.offsetTop + element.clientHeight);

            if (bottom < 0) {
              container.scrollTop -= bottom;
            }
            if (top < 0) {
              container.scrollTop += top;
            }
          }
        });
      }
    };

    return (
      <ComboboxElement {...props}>
        <ComboboxInput
          name={name}
          onKeyDown={onKeyDown}
          ref={ref}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          aria-invalid={trueOrUndef(hasError)}
        />
        <ComboboxPopover ref={popoverRef}>
          <ComboboxList>{children}</ComboboxList>
        </ComboboxPopover>
      </ComboboxElement>
    );
  }
);
Combobox.displayName = "Combobox";

export function ComboxOptions(options: string[]) {
  return options.map((value, i) => {
    return (
      <ComboboxOption key={value} index={i} value={value}>
        <ComboboxOptionText />
      </ComboboxOption>
    );
  });
}

export default Combobox;
