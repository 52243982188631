import { css } from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";

/**
 * <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.475 11.775l-4.5-4.5a.68.68 0 01-.225-.525.68.68 0 01.225-.525c.3-.3.75-.3 1.05 0L9 10.2l3.975-3.975c.3-.3.75-.3 1.05 0 .3.3.3.75 0 1.05l-4.5 4.5c-.3.3-.75.3-1.05 0z" fill="#002891"/></svg>
 */
export const dropDownArrowCss = css`
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguNDc1IDExLjc3NWwtNC41LTQuNWEuNjguNjggMCAwMS0uMjI1LS41MjUuNjguNjggMCAwMS4yMjUtLjUyNWMuMy0uMy43NS0uMyAxLjA1IDBMOSAxMC4ybDMuOTc1LTMuOTc1Yy4zLS4zLjc1LS4zIDEuMDUgMCAuMy4zLjMuNzUgMCAxLjA1bC00LjUgNC41Yy0uMy4zLS43NS4zLTEuMDUgMHoiIGZpbGw9IiMwMDI4OTEiLz48L3N2Zz4=");
  background-position: calc(100% - ${pxToRem(10)}) 50%;
  background-repeat: no-repeat;
`;
