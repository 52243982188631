import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";

/**
 * Center aligned page to match Navigation
 * width
 */
export const PageContainer = styled.div`
  width: 100%;
  max-width: var(--page-max-width);
  margin: auto;
  padding-left: var(--spacing-large);
  padding-right: var(--spacing-large);
  padding-top: var(--spacing-large);
  margin-bottom: ${pxToRem(180)};
`;
