import { pxToRem } from "@input-output-hk/px-to-rem";
import React from "react";
import styled from "styled-components";

export const StyledInput = styled.input`
  background-color: var(--input-background-color);
  border: ${pxToRem(1)} solid var(--input-border-color);
  border-radius: 0.5rem;
  color: var(--input-text-color);
  padding: 0.8125rem 1.25rem;
  width: 100%;
  font-family: inherit;
  font-size: 1rem;

  &[disabled] {
    background-color: var(--input-disabled-background-color);
    cursor: not-allowed;
  }

  &:hover:not([disabled]) {
    border-color: var(--input-border-color-hover);
  }
  &::placeholder {
    font: inherit;
    color: var(--input-placeholder-color);
  }
  &[aria-invalid] {
    border-color: var(--input-error-border-color);
  }
`;
export const ErrorMessage = styled.p`
  color: var(--input-error-color);
  margin: 0 0 0 0.3rem;
`;

const Input = React.forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithoutRef<typeof StyledInput>
>((props, ref) => (
  <>
    <StyledInput ref={ref} {...props}></StyledInput>
    {props["aria-invalid"] && (
      <ErrorMessage>
        {props["aria-errormessage"]
          ? props["aria-errormessage"]
          : "Please enter a valid input"}
      </ErrorMessage>
    )}
  </>
));

Input.displayName = "Input";

export default Input;
